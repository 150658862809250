.tip-transakcije {
  &-po-ulaznom-racunu {
    background: #def2d0;
    color: #245900;
  }

  &-po-izlaznom-racunu {
    color: #e60000;
    background: rgba(255, 89, 89, 0.2);
  }

  &-po-internoj-otpremnici{
    color: #985192;
    background-color: rgba(152, 81, 146, 0.2);
  }

  &-po-povratnici {
    color: #2ca3ce;
    background-color: rgba(41, 150, 190, 0.2);
  }

  &-manuelno {
    color: #495057;
    background-color: rgba(73, 80, 87, 0.2);
  }

  &-po-predracunu {
    background: #def2d0;
    color: #245900;
  }

  &-po-otpremnici {
    background: #def2d0;
    color: #245900;
  }

  &-po-popisu {
    background: rgb(240, 210, 154);
    color: rgb(126, 82, 0);
  }

  &-po-radnom-nalogu {
    background: dodgerblue;
    background-color: rgba(41, 150, 190, 0.2);
  }

  &-po-uvozu {
    background: #b8d1ff;
    color: #833171;
  }
}
