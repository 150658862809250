.h-common {
  font-family:
    "Montserrat",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  color: var(--gray-700, #343a40);
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
}

h1,
h2,
h3,
h4,
h5 {
  @extend .h-common;
}
