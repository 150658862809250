.datum-valute-table {
  &-true {
    color: #e60000;
  }
  &-false {
    color: #245900;
  }
}

.vrsta-racuna-table {
  &-izlazni {
    background: rgba(255, 89, 89, 0.2);
    color: #e60000;
  }

  &-ulazni {
    background: #def2d0;
    color: #245900;
  }
}