.status-racuna {
  &-nacrt {
    color: #ba5014;
    background-color: #fed8b0;
  }

  &-poslato {
    color: #0f54a6;
    background-color: #c1ddfe;
  }

  &-otkazano {
    color: #bfb8af;
    background-color: rgba(153, 174, 187, 0.2);
  }

  &-stornirano {
    color: #6b3cc5;
    background-color: #dcd4fe;
  }

  &-prihvaceno {
    background: #def2d0;
    color: #064e3b;
  }

  &-odbijeno {
    color: #be294a;
    background-color: #fecad0;
  }

  &-slanje-greske {
    color: #be294a;
    background-color: #fecad0;
  }

  &-obrisano {
    color: #8898aa;
    background-color: rgba(73, 80, 87, 0.2);
  }

  &-novo {
    color: #01b9ff;
    background-color: rgba(135, 208, 225, 0.2);
  }

  &-pregledano {
    color: #0f54a6;
    background-color: #c1ddfe;
  }

  &-podsetnik-poslat {
    color: #e40071;
    background-color: rgba(152, 81, 146, 0.2);
  }

  &-slanje {
    color: #b6690d;
    background-color: #fde79c;
  }

  &-ponisteno {
    color: #e60000;
    background-color: rgba(255, 89, 89, 0.2);
  }
}

.status-crf {
  &-aktivan {
    background: #def2d0;
    color: #064e3b;
  }
  &-invalid {
    color: #e60000;
      background-color: rgba(255, 89, 89, 0.2);
  }
  &-otkazan {
    color: #bfb8af;
      background-color: rgba(153, 174, 187, 0.2);
  }
  &-delimicno-settled {
    color: #ba5014;
    background-color: #fed8b0;
  }
  &-settled {
    color: #01b9ff;
    background-color: rgba(135, 208, 225, 0.2);
  }
  &-dodeljen {
    color: #0f54a6;
    background-color: #c1ddfe;
  }
  &-profaktura {
    color: #6b3cc5;
    background-color: #dcd4fe;
  }
  &-greska {
    color: #e60000;
    background-color: rgba(255, 89, 89, 0.2);
  }
}
