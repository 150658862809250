.status-radnog-naloga {
  &-nacrt {
    color: #e58410;
    background-color: rgba(240, 151, 45, 0.2);
  }

  &-zapocet {
    color: #2ca3ce;
    background-color: rgba(41, 150, 190, 0.2);
  }

  &-otkazan {
    color: #99aebb;
    background-color: rgba(153, 174, 187, 0.2);
  }

  &-zavrsen {
    background: #def2d0;
    color: #245900;
  }
}
