.vrsta-radnog-naloga {
    &-proizvodnja_za_lager {
        color: #e58410;
        background-color: rgba(240, 151, 45, 0.2);
    }

    &-proizvodnja_po_narudzbini {
        color: #2ca3ce;
        background-color: rgba(41, 150, 190, 0.2);
    }

    &-evidencija_vremena_i_materijala {
        color: #99aebb;
        background-color: rgba(153, 174, 187, 0.2);
    }

    &-usluzna_delatnost {
        background: #def2d0;
        color: #245900;
    }
    
    &-gradjevina{
        background: #def2d0;
        color: #245900;
    }
}