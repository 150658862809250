/* Add your customizations of the theme styles here */

.gap {
  &-0 {
    gap: 0;
  }

  &-6 {
    gap: 6px;
  }

  &-8 {
    gap: 8px;
  }

  &-12 {
    gap: 12px;
  }

  &-25 {
    gap: 25px;
  }
}

.flex {
  &-50 {
    flex: 50%;
  }
}

.p-autocomplete-token {
  margin: 3px;
}

.p-menu-overlay {
  z-index: 999999 !important;
}

.profile-item {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

p-dropdown.ng-dirty > .p-dropdown {
  border-color: 1px solid #ced4da;
}

p-dropdown.ng-invalid.ng-touched > .p-dropdown,
p-inputnumber.ng-invalid.ng-touched > .p-inputnumber > .p-inputtext {
  border-color: #e60000;
}

.topbar-badge {
  font-size: 9px !important;
}

.flex-grow {
  flex: 1 1 auto;
}
