.status-uplate-racuna {
  &-neplacen {
    color: #BE294A;
    background-color: #FECAD0
  }

  &-delimicno-placen {
    color: #BA5014;
    background-color: #FED8B0;
  }

  &-izmiren {
    background: #def2d0;
    color: #064E3B;
  }

  &-nov {
    color: #BA5014;
    background-color: #FED8B0;
  }

  &-delimicno-iskoriscen {
    color: #0F54A6;
    background-color: #C1DDFE;
  }

  &-iskoriscen {
    background: #def2d0;
    color: #064E3B;
  }

  &-empty {}
}