.kodit-success {
  background-color: #10B981;
}

.kodit-error {
  background-color: #e60000;
}

.p-button-white {
  background-color: #fff;
  color: #0F67D1;
}

.p-button:enabled:hover {
  &.p-button-white {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }
}

.fixed-input {
  width: 200px;
}
