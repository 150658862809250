.tip-evidencije {
  &-izlazna-faktura{
    background: rgba(255, 89, 89, 0.2);
    color: #e60000;
  }
  &-ulazna-faktura {
    background: #def2d0;
    color: #245900;
  }

  &-interni-obracun {
    color: rgba(73, 80, 87, 0.6);
    background-color: rgba(73, 80, 87, 0.2);
  }

  &-avansni-racun {
    color: #985192;
    background-color: rgba(152, 81, 146, 0.2);
  }

  &-promet-stranih-lica {
    color: #87d0e1;
    background-color: rgba(135, 208, 225, 0.2);
  }

  &-dokument-o-smanjenju {
    color: #6d7d81;
    background-color: rgba(64, 128, 4, 0.2);
  }

  &-dokument-o-povecanju {
    color: #6480d1;
    background-color: rgb(109 215 100 / 20%);
  }
}