.status-izvoda {
  &-nov {
    color: #87d0e1;
    background-color: rgba(135, 208, 225, 0.2);
  }

  &-obrada-u-toku {
    color: #2ca3ce;
    background-color: rgba(41, 150, 190, 0.2);
  }

  &-obraden {
    background: #def2d0;
    color: #245900;
  }
}
