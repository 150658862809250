.je-u-sistemu-pdv {
  &-da {
    background: #def2d0;
    color: #245900;
  }

  &-ne {
    color: FF5959;
    background-color: rgba(255, 89, 89, 0.2)
    ;
  }
}
