.tip-pravnog-lica {
  &-kupac {
    background: #def2d0;
    color: #245900;
  }

  &-dobavljač {
    color: #2ca3ce;
    background-color: rgba(41, 150, 190, 0.2);
    margin-top: 5px;
  }
}
