.status-pojedinacne-evidencije {
  &-u-pripremi {
    color: #e58410;
    background-color: rgba(240, 151, 45, 0.2);
  }

  &-evidentirano {
    color: #2ca3ce;
    background-color: rgba(41, 150, 190, 0.2);
  }

  &-korigovano {
    color: #99aebb;
    background-color: rgba(153, 174, 187, 0.2);
  }

  &-zamenjeno {
    color: rgba(28, 115, 153, 0.8);
    background-color: rgba(28, 115, 153, 0.2);
  }

  &-ponisteno {
    color: #e60000;
    background-color: rgba(255, 89, 89, 0.2);
  }
}
