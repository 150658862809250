.status-uplate-izvoda {
  &-racun-nije-odredjen {
    background: rgba(255, 89, 89, 0.2);
    color: #e60000;
  }

  &-ceka-evidenciju {
    background: rgba(41, 150, 190, 0.2);
    color: #2ca3ce;
  }

  &-evidentirano {
    background: #def2d0;
    color: #245900;
  }

  &-delimicno-evidentirano {
    color: #e58410;
    background-color: rgba(240, 151, 45, 0.2);
  }
}
