//@import './assets/styles/ekompanija-default-style.scss';
@import "./assets/layout/css/layout-light.scss";
@import "./assets/theme/blue/theme-light.scss";
@import "node_modules/primeflex/primeflex.scss";
@import "apps/core/src/assets/sass/variables/varoverride.scss";
@import "~viewerjs/dist/viewer.css";

/** Kodit styles aa */
@import "./assets/sass/kodit/racun/racun.scss";
@import "./assets/sass/kodit/pravno-lice/pravno-lice.scss";
@import "./assets/sass/kodit/status-uplate-izvoda.scss";
@import "./assets/sass/kodit/general.scss";
@import "./assets/sass/kodit/roba/roba.scss";
@import "./assets/sass/kodit/izvod/izvod.scss";
@import "./assets/sass/kodit/pdv-evidencija/pdv-evidencija.scss";
@import "./assets/sass/kodit/joyride/joyride.scss";
@import "./assets/sass/kodit/headers.scss";

/* inter-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/inter/Inter-Regular.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-bold - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/inter/Inter-Bold.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-thin - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/inter/Inter-Thin.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-semi-bold - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/inter/Inter-SemiBold.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-medium - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/inter/Inter-Medium.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-light - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/inter/Inter-Light.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-extra-light - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/inter/Inter-ExtraLight.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-extra-bold - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/inter/Inter-ExtraBold.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-black - latin */
@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/inter/Inter-Black.ttf") format("ttf"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.layout-content {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1620px;
}

.even-columns {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    flex-basis: 100%;
  }
}

.even-flex {
  display: flex;

  & > * {
    flex-basis: 100%;
  }
}

.form-dialog-footer {
  width: 100%;
  height: 100%;
  display: block;
  padding-top: 25px;
}

.p-breadcrumb {
  border: none;
  padding: 0;
  background: none;
  padding-left: 0px !important;
}

// * {
//   border-radius: 0 !important;
// }

//
// da bi nam se lepo prikazalo u dialogu
// potrebno dodati appendTo="body" u component
//
.p-multiselect-panel,
.p-dropdown-panel {
  z-index: 999999999 !important;
}

.p-autocomplete-panel {
  z-index: 999999999 !important;
}

.p-datepicker {
  z-index: 999999999 !important;
}

//
// ?
//
.pac-container {
  z-index: 999999999 !important;
}

//
// kada je button disabled prikazi drugaciji cursor
//
.p-button:disabled {
  cursor: not-allowed !important;
}

//
// tooltip da bude ispred svih (zbog tooltipa u dialogu)
//
.p-tooltip {
  z-index: 999999 !important;
  pointer-events: none;
}

.p-dialog {
  background-color: #fff;
}

.p-dialog .p-dialog-header {
  display: flex;
  padding-right: 0;
  padding-left: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  color: #0f67d1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-bottom: 1px solid #eaecf0;
}

.p-dialog .p-dialog-content {
  position: relative;
  flex: 1 1 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-dialog .p-dialog-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  //border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.konfiguracije-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f8f9fa;
  border-radius: 5px;

  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;

    &:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:first-child {
      border-top-width: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .list-group-item {
      border-width: 0 0 1px;
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #f8f9fa;

      &:last-child {
        border-bottom-width: 0;
      }

      &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }

      .text-success {
        font-size: 8px;
        color: #028824 !important;
      }

      .text-muted {
        font-size: 8px;
        color: #869ac0 !important;
      }
    }
  }
}

.layout-topbar {
  &.sticky {
    position: sticky;
    top: 0px;
    z-index: 999999;
  }
}

.layout-topbar .logo:focus {
  box-shadow: none;
}

//
// boja bordera na hover reda u tabeli
//
// .p-datatable.p-datatable-hoverable-rows
//   .p-datatable-tbody
//   > tr:not(.p-highlight):hover {
//   background: #f8f9fa !important;
// }

.p-datatable.p-datatable-customers .p-datatable-header {
  line-height: 33px;
  font-size: 1.2rem !important;
}

.p-datatable.kodit-table .p-datatable-header {
  line-height: 33px;
  font-size: 1.2rem !important;
}

// Variables
$grey: #f2f2f2;
$purple: #7769e4;
$cyan: #64d4d6;
$red: #ff7a86;

// On-load
.on-load {
  opacity: 0;
  animation: fade-in-up 1s forwards;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Spinner
.spinner {
  pointer-events: none;
  width: 32px;
  height: 32px;
  border: 2px solid transparent;
  border-color: $grey;
  border-top-color: $purple;
  border-radius: 50%;
  animation:
    spin 1s,
    colour-wheel 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes colour-wheel {
  0% {
    border-top-color: $purple;
  }
  33% {
    border-top-color: $cyan;
  }
  66% {
    border-top-color: $red;
  }
  100% {
    border-top-color: $purple;
  }
}

// Misc.
.unaut {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
  place-items: center;
}

//
// prikaz i sakrivanje scrollbara u meniju
//
.layout-menu-container {
  overflow: hidden;
}

.layout-menu-container:hover {
  overflow: auto;
}

.p-card {
  .no-shadow {
    box-shadow: none;
  }
}

//
// da bismo errore ako ih ima vise prikazali jedan ispod drugog
// u kombinaciji sa \n
//
.p-toast-detail {
  white-space: pre-line !important;
}

//
// da bismo videli confirmation dialog ispred dialoga (ako ih ima vise)
//
.p-dialog-mask.p-component-overlay {
  z-index: 999999 !important;
}

//
// Advanced filter above each table
//
.kodit-filter {
  gap: 0.5em;
}

.kodit-filter > * {
  display: none;
  //width:100%;

  &.show {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    display: inline-flex;
  }
}

.hide-divider {
  display: none;
}

.table-row-highlight {
  &.tip-transakcije {
    &.izlaz {
      border-left: 10px solid orange;
      //background-color: cornsilk !important;
    }

    &.ulaz {
      border-left: 10px solid #97a97c;
      //background-color: beige !important;
    }

    &.rezervacija {
      border-left: 10px solid cornflowerblue;
      //background-color: aliceblue !important;
    }
  }
}

.layout-menuitem-icon {
  height: 24px;
  width: 24px;
  font-size: 1rem;
  margin-right: 16px;

  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  border-color: #c9d2e3 !important;
}

.layout-menu-container {
  overflow: unset !important;
}

.p-scrollpanel {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }

  &.custombar2 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
      border-bottom: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: var(--layer-2);
      border-radius: 0;
      opacity: 1;
      transition: background-color 0.2s;
    }
  }
}

.p-divider-solid.p-divider-vertical:before {
  border-left: 2px #dee2e6;
}

.p-divider{
  margin: 16px 0;
  color: #E0E0E0;
}

.section-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #828f99;
}

.visibility-hidden {
  visibility: hidden;
}

.w-100 {
  width: 100%;
}

.mw-500 {
  max-width: 500px;
  margin: auto;
}

.k-card {
  background: #ffffff !important;
  border-radius: 8px !important;
  border: 2px solid #eaecf0 !important;
  // box-shadow: 0 1px 5px rgb(38 60 85 / 4%);
  .k-w-50 {
    width: 50%;
  }

  .p-text {
    color: #6c757d;
  }

  padding: 25px !important;
  padding-bottom: 20px !important;

  .k-icon {
    width: 30px;
  }

  .k-label-warning {
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #e60000;
  }

  ._header {
    //margin-bottom: 25px;

    ._icon {
      color: #0f67d1;
      font-size: 3rem;
      background: #f8f9fa;
      border: 1px solid #dee2e6;
      box-sizing: border-box;
      padding: 1.3rem;
      border-radius: 50%;
    }

    ._icon_back {
      color: #f0f1f9;
    }

    ._icon_front {
      color: #0f67d1;
      font-size: 3rem;
    }

    // #303E4D,#2C3849,#6698C8,#FFFFFF,#4A5664,#FFFFFF,#94E864,#78AF8F,#2C3849,#FFFFFF
    ._title {
      line-height: 100%;
      margin-left: 0px;
      display: flex;
      align-items: center;
      color: #2e394b;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;

      span {
        color: #6c757d;
        padding-left: 0.8rem;
      }

      &.not-upper {
        text-transform: none;
      }
    }

    ._subtitle {
      color: #6c757d;
      margin-top: 0.5rem;
    }
  }

  .body_title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #495057;
  }

  .p-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #495057;
  }

  .card-header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    margin-left: 0px;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #0f67d1;
  }
}

.p-scrollpanel-bar {
  background-color: var(--layer-2);
  border-radius: 0;
  opacity: 1;
  transition: background-color 0.2s;
}

.p-message .p-message-detail {
  margin-left: 1rem;
  text-align: left;
}

.p-sidebar-mask.p-component-overlay {
  transition-duration: 0.5s;
  background-color: rgba(5, 25, 64, 0.6) !important;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(5, 25, 64, 0.6);
  transition-duration: 0.5s;
}

.ek-ta-c {
  text-align: center;
}

.ek-w-50 {
  width: 50%;
}

.ek-w-70 {
  width: 70%;
}

.ek-w-100 {
  width: 100%;
}

.ek-p-0 {
  padding: 0px;
}

.p-chip {
  background-color: #f8f9fa;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.5rem;
  border: 1px solid #dee2e6;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}

.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  border-radius: 3px;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}

.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #df9eea;
}

// .p-dialog-content {
//   overflow-y: unset !important;
// }

.dialog-scroll-panel {
  .p-scrollpanel-content {
    background: white;
  }
}

.p-scrollpanel {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }

  &.custombar2 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
      border-bottom: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background: #42526e;
      border-radius: 0;
      opacity: 1;
      transition: background-color 0.2s;
    }
  }
}

.flex-gap-1 {
  gap: 1em;
}

//#303E4D,#2C3849,#6698C8,#FFFFFF,#4A5664,#FFFFFF,#94E864,#78AF8F,#2C3849,#FFFFFF
.ek-max-w-200-px {
  max-width: 200px;
}

.layout-menu-container {
  padding: 0;
}

//#region light version menu

.layout-sidebar-darkgray .layout-sidebar {
  background: #fff;
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: #0f67d1;
  font-size: 14px;
}

.layout-topbar .logo {
  // background-color: #0F67D1;
  width: 16rem;
  margin: 0;
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu a {
  color: #2e394b !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 10px;
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:focus {
  box-shadow: #0f67d1 0 0 0 2px inset;
}

.layout-sidebar-darkgray .layout-sidebar li.active-menuitem {
  background: none !important;
}

.layout-menu ul {
  li {
    margin-right: 7px !important;
    border-radius: 4px;
  }
}

.layout-menu ul a.active-route {
  &:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 4px;
    left: 0px;
    top: 12px;
    background: #0f67d1;
    border-radius: 0px 1.998px 1.998px 0px;
  }

  background-color: #eff6ff !important;

  & span {
    color: #0f67d1 !important;
  }

  & i {
    color: #0f67d1 !important;
  }
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:hover {
  background-color: #eff6ff !important;

  & span {
    color: #2e394b !important;
  }
}

//#endregion light version menu

//#region grey version menu

// .layout-sidebar-darkgray .layout-sidebar {
//   background: #303e4d;
// }

// .layout-menu ul a.active-route {
//   background-color: #0F67D1;
// }

// .layout-sidebar-darkgray .layout-sidebar .layout-menu li a:focus {
//   box-shadow: none;
// }

// .layout-sidebar-darkgray .layout-sidebar .layout-menu li a:hover {
//   background-color: #4a5664;
// }
// .layout-sidebar {
//   &.white {
//     margin: 0;
//     padding-bottom: 12px;
//   }
// }
//#endregion grey version menu

.profile-name {
  text-transform: capitalize;
}

// .delatnosti-panel {

//   .p-dropdown-item{
//     background-color: red;
//   }

//   &.p-multiselect-panel,
//   .p-dropdown-panel {
//     width: 350px;
//   }

//   // &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
//   //   white-space: nowrap;
//   //   overflow: hidden;
//   //   text-overflow: ellipsis;
//   // }

//   .p-multiselect-panel,
//   .p-dropdown-panel {
//     max-width: 380px;
//   }
// }

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: break-spaces;
}

.p-multiselect-panel,
.p-dropdown-panel {
  max-width: 500px;
}

.profile-item-a {
  background: #f8f9fa;
  border: 0.5px solid #dee2e6;
  border-radius: 3px;
}

.card {
  background-color: white;
  border-radius: 8px !important;
  border: 2px solid #eaecf0 !important;
  padding: 1rem;
  box-shadow: none;
  //box-shadow: 0 1px 3px #00000026;
}

.item-badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 500;
  // line-height: 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.4rem;
  text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
    width: max-content;
  // display: flex;
  //   align-items: center;
  //   flex-shrink: 0;
  //   min-width: 0px;
  //   max-width: 100%;
  //   height: 18px;
  //   border-radius: 3px;
  //   padding-left: 8px;
  //   padding-right: 8px;
  //   font-size: 13px;
  //   line-height: 120%;
  //   // color: rgb(93, 23, 21);
  //   // background: rgb(255, 226, 221);
  //   margin: 0px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   width: max-content;
}

.p-dataview .p-dataview-emptymessage {
  padding-left: 0px !important;
}

object {
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.loading-card {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.loading-skeleton-bar {
  height: 1rem;
  background: #dfe3e8;
  border-radius: 3px;
  animation: shimmer 0.8s linear infinite alternate;
}

.loading-skeleton-bar + .loading-skeleton-bar {
  margin-top: 1.2rem;
}

.loading-skeleton-bar:last-child:not(:first-child) {
  width: 80%;
}

.layout-menu ul a {
  padding: 0.625rem 0.938rem;
}

.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  margin: 0.938rem 0 0.469rem 0;
  padding: 0.625rem 1.876rem 0.625rem 0.876rem;
  font-weight: 400;
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu .menu-separator {
  border-top: none;
}

.layout-menuitem-icon {
  font-size: 1.2rem;
  color: rgb(66, 82, 110);
  margin-right: 0.7rem;
}

.layout-menu ul a.active-route {
  font-weight: 400;
}

.info-badge {
  border-radius: 4px;
  padding: 0.5rem;
}

// .p-progressbar .p-progressbar-label {
//   color: white;
//   font-size: 0.8rem;
// }

.kodit-card-tab {
  .p-tabview .p-tabview-panels {
    background: inherit;
    padding: inherit;
    border: none;
    color: inherit;
    margin-top: 1.5rem;
  }

  .p-tabview-nav {
    gap: 21px;
    border-radius: 8px;
  }

  .p-tabview-nav-container {
    background-color: #ffffff !important;
    border: 1px solid rgb(222, 226, 230);
    border-radius: 8px;
    border-bottom: none;
  }

  .p-tabview-title {
    line-height: 2;
  }
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 16px;
}

.tttest {
  .p-inline-message {
    margin: 1rem 0;
    padding: 1rem 1.5rem;
    border-radius: 3px;
    width: 100%;
  }
}

.layout-topbar img {
  height: 1.7rem;
}

.layout-topbar .logo-image {
  height: 2.2rem;
}

.p-dialog {
  border-radius: 0.4rem;
}

.p-overlaypanel {
  box-shadow:
    0 1px 15px #19264026,
    0 1px 3px #1926401f,
    inset 0 -1px #19264008;

  .p-overlaypanel-content {
    padding: 0.5rem 0 !important;
  }
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.75rem 0.5rem;
}

.p-sidebar {
  box-shadow: inset 0 -1px 0 #e0e7ef;
}

.p-sidebar-mask.p-component-overlay {
  margin-top: 58px;
  z-index: 997 !important;
}

.layout-topbar .topbar-menu > li > ul {
  z-index: 999999 !important;
  box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));
}

.flex-1-1 {
  flex: 1 1 0px;
}

.layout-menu {
  margin-top: 20px;
  margin-left: 8px;
  margin-right: 8px;

  ul {
    margin-left: 8px;
  }
}

.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  padding: 0px 10px;
  color: #5e6c84;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.45455;
  text-transform: uppercase;
}

.layout-sidebar {
  margin-top: 56px;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #42526e;
}

h1,
h2,
h3,
h4 {
  color: #172b4d;
}

// .p-datatable .p-datatable-tbody > tr{
//   color: #172B4D;
// }

// body{
//   color: #172B4D;
// }
// overflow: auto;
//     height: calc(100vh - 80px);

.layout-content-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
}

body {
  overflow: hidden;
}

.p-tooltip {
  z-index: 999999999 !important;
}

.p-toast .p-toast-message.p-toast-message-warn {
  border: none;
}

.p-toast .p-toast-message.p-toast-message-error {
  border: none;
}

.p-toast .p-toast-message.p-toast-message-success {
  border: none;
}

.p-tabview .p-tabview-nav {
  // border: 1px solid #e6ebf1 !important;
}

.kodit-card-tab .p-tabview-nav-container {
  border-color: #e6ebf1 !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.field-label {
  color: var(--gray-700, #343a40);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 1367px) {
  .info_icon {
    display: none;
  }

  ._stranka_info_small_wp {
    display: block !important;
  }

  ._stranka_info_large_wp {
    display: none !important;
  }

  .stranka-description {
    flex-direction: row !important;
  }
}

.p-inputtext, textarea {
  border-radius: 4px;
  border: 2px solid #eaecf0;
  background: #fff;
}

.p-dropdown {
  border-radius: 4px;
  border: 2px solid #eaecf0;
  background: #fff;
}

.joyride-step__container {
  box-shadow: none !important;
  border-radius: 8px;
}

.joyride-button {
  color: #ffffff;
  background: #0f67d1 !important;
  border: 1px solid #0f67d1 !important;
  padding: 0.5rem 1rem;
  font-size: 14px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 4px;

  &:hover {
    background: #0e5dbc !important;
    color: #ffffff !important;
    border-color: #0e5dbc !important;
  }
}

.joyride-step__close {
  width: 12px !important;
  height: 12px !important;
}

