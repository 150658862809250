.layout-content-wrapper {
    @include flex();
    @include flex-direction-column();
    @include flex-justify-between();
    min-height: 100vh;
    transition: margin-left $animationDuration $animationTimingFunction;
}

.layout-content {
    padding: 2rem;
    flex: 1 1 auto;
    -ms-flex: 1 1 auto;
}
