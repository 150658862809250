.pdv-status {

  &-u-pripremi {
   color: #e58410;
   background-color: rgba(240, 151, 45, 0.2);
 }

&-korigovano  {
   color: #2ca3ce;
   background-color: rgba(41, 150, 190, 0.2);
 }

&-evidentirano {
   background: #def2d0;
   color: #245900;
 }

&-ponisteno {
   color: #e60000;
   background-color: rgba(255, 89, 89, 0.2);
 }
}
