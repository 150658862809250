/* Add your customizations of the layout styles here */

a {
  color: #0F67D1;
}

.p-inputtext.p-component:disabled {
  background: rgba(206, 212, 218, 0.3);
}

.p-image-preview {
  max-height: 800px !important;
}

.p-image-mask.p-component-overlay {
  z-index: 999999 !important;
}

.p-field {
  min-width: 200px;
}

.p-multiselect {
  width: 100%;
}

.p-dropdown {
  width: 100%;
}
